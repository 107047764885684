import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import BodyBasePanel from '../components/pages/BodyBasePanel';
import { img } from '../components/Utils';
import { Typography, Link, MobileStepper, Button, useTheme, Grid } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
    root: {},
    intros: {
        display: 'flex',
        flexDirection: 'column',
        width: 550,
        padding: 8,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    logo: {
        height: 400,
        width: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            width: '100%',
        }
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoStore: {
        height: 55,
        //width: 180,
        [theme.breakpoints.down('xs')]: {
            width: 180,
            height: 'auto',
        }
    },
    stepper: {
        marginTop: 8,
    }
});

const tutorialSteps = [
    {
        label: 'ESCOLASTIA es la plataforma digital de gestión y control escolar, que te brinda el control y acceso a la información escolar de tus hijos en sus colegios.',
        imgPath: img("/images/intro/intro_1.png"),
    },
    {
        label: 'Consulta la información educativa, financiera, calendarios, comunicados, entre otros datos sobre los alumnos asociados a tu cuenta de forma rápida y segura.',
        imgPath: img("/images/intro/intro_2.png"),
    },
    {
        label: 'Recibe notificaciones en tu celular sobre eventos, comunicados, tareas, asistencia, estado de cuenta y más, con lo cual tendrás el control y estarás informado.',
        imgPath: img("/images/intro/intro_3.png"),
    },
    {
        label: 'Podrás consultar calendarios y días festivos, periodos de exámenes, fechas de pago, inscripciones y todas las actividades relacionadas con los alumnos y el colegios',
        imgPath: img("/images/intro/intro_4.png"),
    },
];

const Home = ({ ...props }) => {
    const { classes } = props
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <BodyBasePanel hideSocialMedia>
                <>
                    <div className={classes.intros}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={tutorialSteps[activeStep].imgPath} alt="intro" className={classes.logo} />
                        </div>
                        <div style={{ marginTop: 24, minHeight: 120 }}>
                            <Typography component="div" align="justify">{tutorialSteps[activeStep].label}</Typography>
                        </div>

                        <MobileStepper
                            variant="dots"
                            steps={4}
                            position="static"
                            activeStep={activeStep}
                            className={classes.stepper}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
                                    Siguiente
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                    Previo
                                </Button>
                            }
                        />

                        <Grid container spacing={2} style={{ marginTop: 16 }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className={classes.logoContainer}>
                                    <Link href="https://play.google.com/store/apps/details?id=com.escolastia.tutores" target="_blank">
                                        <img src={img("/images/google-store.png")} alt="google-store" className={classes.logoStore} />
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className={classes.logoContainer}>
                                    <Link href="https://apps.apple.com/app/escolastia/id1504365972" target="_blank">
                                        <img src={img("/images/apple-store.png")} alt="apple-store" className={classes.logoStore} />
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className={classes.logoContainer}>
                                    <Link href="https://appgallery.huawei.com/app/C110422037" target="_blank">
                                        <img src={img("/images/appgallery-store.png")} alt="appgalley-store" className={classes.logoStore} />
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </>
            </BodyBasePanel>
        </>
    )
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);