
export const cambiarPasswordCuenta = (clienteId, body) => ({
    url: `clientes/${clienteId}/password`,
    method: 'PUT',
    data: body
})

export const cambiarEmailCuenta = (clienteId, body) => ({
    url: `clientes/${clienteId}/email`,
    method: 'PUT',
    data: body
})

export const recuperarPassword = (email, recaptcha) => ({
    url: 'public/clientes/restablecepassword',
    method: 'POST',
    params: {
        recaptcha
    },
    data: email,
    headers: {
        'Content-Type': 'text/plain'
    },
})

export const validaToken = (token, origen, password) => ({
    url: `public/clientes/token/${token}`,
    method: 'POST',
    params: {
        origen
    },
    data: password,
    headers: {
        'Content-Type': 'text/plain'
    },
})