import React from "react";
// import PropTypes from "prop-types";
import { default as MaterialTextField } from "@material-ui/core/TextField";
import isEmpty from "lodash/isEmpty";

const TextField = ({ id, errors, ...props }) => (
  <MaterialTextField
    {...props}
    id={id}
    error={!isEmpty(errors) && !isEmpty(errors[id])}
    helperText={(!isEmpty(errors) && errors[id]) || props.helperText}
  />
);

TextField.propTypes = {

};
TextField.defaultProps = {

};

export default TextField;