import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

// Material UI Styles
import { withStyles } from '@material-ui/core/styles';
import LoadingPanel from './LoadingPanel';

const styles = theme => ({
});

class LoadingDialog extends Component {
    render() {
        let { msg } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onExited={this.props.onExited}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent style={{ overflow: 'hidden' }}>
                    <LoadingPanel msg={msg} />
                </DialogContent>
            </Dialog>
        )
    }
}

LoadingDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    msg: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onExited: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(LoadingDialog);