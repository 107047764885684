import React from 'react'
import Axios from 'axios'
import LRU from 'lru-cache'
import { AppContext } from '../App';
// import Qs from 'qs';

const actions = {
    REQUEST_START: 'REQUEST_START',
    REQUEST_END: 'REQUEST_END'
}

const ssrPromises = []

const isProd = process.env.NODE_ENV === 'production';

//****NO MOVER ****/
let SERVER_API_PROTOCOL = window.location.protocol;
let SERVER_API_HOST = window.location.host; //process.env.REACT_APP_SERVER_API_HOST || 'playground.escolastia.com';
let SERVER_API_PORT;
//****NO MOVER ****/

if (!isProd) {
    SERVER_API_PROTOCOL = 'http:'
    SERVER_API_HOST = process.env.REACT_APP_SERVER_API_HOST || 'localhost'
    SERVER_API_PORT = process.env.REACT_APP_SERVER_API_PORT || '8082';
}

export const API_BASE_URL = `${SERVER_API_PROTOCOL ? SERVER_API_PROTOCOL + '//' : ''}${SERVER_API_HOST}${SERVER_API_PORT ? ':' + SERVER_API_PORT : ''}/api`

let cache = new LRU()
let axiosInstance = Axios.create({
    baseURL: API_BASE_URL,
    // headers: {
    // 'X-Api-Version': '0.0.1'
    // },
    withCredentials: true,
}) //DefaultAxios

export function configure(options) {
    if (options.axios) {
        axiosInstance = options.axios
    }

    if (options.cache) {
        cache = options.cache
    }
}

export function loadCache(data) {
    cache.load(data)
}

export async function serializeCache() {
    await Promise.all(ssrPromises)

    ssrPromises.length = 0

    return cache.dump()
}

async function cacheAdapter(config) {
    const cacheKey = JSON.stringify(config)
    const hit = cache.get(cacheKey)

    if (hit) {
        return hit
    }

    delete config.adapter

    const response = await axiosInstance(config)

    const responseForCache = { ...response }
    delete responseForCache.config
    delete responseForCache.request

    cache.set(cacheKey, responseForCache)

    return response
}

function createInitialState(options) {
    return {
        loading: !options.manual
    }
}

function reducer(state, action) {
    // console.log(state, action);
    switch (action.type) {
        case actions.REQUEST_START:
            return {
                // ...state,
                cancel: action.cancel,
                loading: true
            }
        case actions.REQUEST_END:
            return {
                ...state,
                loading: false,
                ...(action.error ? {} : { data: action.payload.data }),
                [action.error ? 'error' : 'response']: action.payload
            }
        default:
            return state
    }
}

async function request(config, dispatch) {
    try {
        const source = Axios.CancelToken.source();
        dispatch({ type: actions.REQUEST_START, cancel: source })
        const response = await axiosInstance({
            ...config,
            cancelToken: source.token
        })
        dispatch({ type: actions.REQUEST_END, payload: response })
    } catch (err) {
        if (Axios.isCancel(err)) {
            console.log(`Call for "${config.url}" was cancelled`);
        } else {
            dispatch({ type: actions.REQUEST_END, payload: err, error: true })
        }
    }
}

function executeRequestWithCache(config, dispatch) {
    request({ ...config, adapter: cacheAdapter }, dispatch)
}

function executeRequestWithoutCache(config, dispatch) {
    return request(config, dispatch)
}

export default function useApi(config, options = { manual: false, defaultError: true }) {
    const { notificarError, actualizaApp } = React.useContext(AppContext);
    if (!config) {
        options.manual = true;
    }

    const [state, dispatch] = React.useReducer(
        reducer,
        createInitialState(options)
    )

    if (typeof window === 'undefined') {
        ssrPromises.push(axiosInstance({ ...config, adapter: cacheAdapter }))
    }

    React.useEffect(() => {
        if (!options.manual) {
            executeRequestWithCache(config, dispatch)
        }
    }, [JSON.stringify(config)])

    React.useEffect(() => {
        if (state.error && state.error.response && state.error.response.status === 401 && options.defaultError === true) {
            //Se venció la sesión
            console.log("Se venció la sesión, necesitas autenticarte de nuevo...");
            actualizaApp({ needUpdateSession: true });
            return;
        }
        if (state.error && options.defaultError === true) {
            let msg = "Servicio no disponible, intente nuevamente";
            if (state.error.response && state.error.response.data && state.error.response.data.error) {
                msg = state.error.response.data.error;
            }
            if (state.error.response && state.error.response.status === 403) {
                msg = "No cuentas con privilegios para esta operación";
            }
            notificarError(msg);
        }
    }, [state]);

    return [
        state,
        configOverride => {
            return executeRequestWithoutCache(configOverride, dispatch)
        }
    ]
}

//For cancel....
const CancelToken = Axios.CancelToken;

export const withCancel = (cancelFn, config) => {
    return {
        ...config,
        cancelToken: new CancelToken(function executor(cancel) {
            // An executor function receives a cancel function as a parameter
            cancelFn(cancel);
        })
    }
}

export const isCancelRequest = (error) => {
    return Axios.isCancel(error);
}

export const withCancelFoo = (config) => {
    const source = Axios.CancelToken.source();
    return [source, {
        ...config,
        cancelToken: source.token
    }]
}