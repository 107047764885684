import React from 'react';
import { Paper, Typography, withStyles, Link, Toolbar, AppBar, Box, withWidth, isWidthDown, Divider } from '@material-ui/core';
import { img } from '../Utils';

//Material UI Icons SVG
import TwitterIcon from '../../icons/Twitter';
import FacebookIcon from '../../icons/Facebook';
import CloseCircleOutlineIcon from '../../icons/CloseCircleOutline';
import isObject from 'lodash/isObject';

const styles = theme => ({
    root: {
        overflow: 'auto',
        padding: theme.spacing(2),
        backgroundSize: 'cover',
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${img("/images/fondo1.jpg")})`,
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#659adc'
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        borderRadius: 16,
    },
    logo: {
        height: 80,
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: 60,
        }
    },
    logoPowerBy: {
        height: 40,
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: 30,
        }
    },
    growH: {
        flexGrow: 1,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
})

const BodyBasePanel = (props) => {
    const { classes, title, subtitle, hideFooter, hideSocialMedia, hideLogo, errorServer } = props;

    const renderContent = () => {
        if (errorServer) {
            let msg = "Servicio no disponible, intente nuevamente";
            if (errorServer.response && errorServer.response.data && errorServer.response.data.error) {
                msg = errorServer.response.data.error;
            }
            return <>
                <Typography variant="h6" align="center" style={{ margin: '24px 0px' }}>
                    {msg}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CloseCircleOutlineIcon style={{ fontSize: 65, alignSelf: 'center', color: '#ff0000' }} />
                </div>
            </>
        } else {
            return props.children;
        }
    }

    const renderPowerBy = () => {
        if (props.showPowerBy) {
            return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 16px' }}>
                <Divider style={{ margin: '16px 0px' }} />
                <Typography variant="caption" align="center" component="div">Powered by</Typography>
                <a href="https://www.escolastia.com"><img src={img("/images/titulo-login-1-small.png")} alt="logo escolastia" className={classes.logoPowerBy} /></a>
            </div>
        }
    }

    return (
        <>
            <div className={classes.root} style={{ height: '100vh' }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={hideFooter ? 0 : 8}
                    minHeight={hideFooter ? "calc(100vh - 32px)" : "calc(100vh - 96px)"}>
                    <Paper className={classes.paper}>
                        {!hideLogo &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href="https://www.escolastia.com"><img src={img("/images/titulo-login-1-small.png")} alt="logo escolastia" className={classes.logo} /></a>
                            </div>
                        }
                        {title && !isObject(title) ?
                            <Typography variant="h6" align='center' style={{ margin: '24px 0px' }}>
                                {title}
                            </Typography>
                            :
                            <>
                                {title}
                            </>
                        }
                        {subtitle &&
                            <Typography variant="body1" align="center" style={{ marginBottom: 24 }}>
                                {subtitle}
                            </Typography>
                        }
                        {renderContent()}
                        {renderPowerBy()}
                    </Paper>
                </Box>
                {!hideFooter &&
                    <AppBar position="fixed" color="secondary" className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="caption">
                                <Link href="https://www.escolastia.com/terminos" target="_blank" style={{ color: 'white', marginRight: 16 }}>{isWidthDown('xs', props.width) ? "Términos" : "Términos y condiciones"}</Link>
                                <Link href="https://www.escolastia.com/privacidad" target="_blank" style={{ color: 'white' }}>{isWidthDown('xs', props.width) ? "Privacidad" : "Aviso de privacidad"}</Link>
                            </Typography>
                            <div className={classes.growH}></div>
                            {!hideSocialMedia &&
                                <div>
                                    <Link href="https://twitter.com/" target="_blank" style={{ marginRight: 16 }}><TwitterIcon style={{ color: 'white' }} /></Link>
                                    <Link href="https://www.facebook.com/" target="_blank"><FacebookIcon style={{ color: 'white' }} /></Link>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                }
            </div>
        </>
    )
}

BodyBasePanel.defaultProps = {
    hideLogo: false,
    hideFooter: false,
    hideSocialMedia: false,
    loading: false,
    showPowerBy: false,
}

export default withWidth()(withStyles(styles)(BodyBasePanel));