import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import { withStyles } from '@material-ui/core/styles';
import BodyBasePanel from '../components/pages/BodyBasePanel';
import LoadingPanel from '../components/LoadingPanel';

const styles = theme => ({

});

const PagoOpenpay3dSecureContinue = ({ ...props }) => {

    return (
        <>
            <BodyBasePanel title={'Validando información'} hideFooter>
                <LoadingPanel />
            </BodyBasePanel>
        </>
    )
}

PagoOpenpay3dSecureContinue.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(PagoOpenpay3dSecureContinue));