import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import ReCAPTCHA from "react-google-recaptcha";

// Material UI Styles
import { withStyles } from '@material-ui/core/styles';

// Material UI Components
import TextField from '../components/TextField';
import Button from '@material-ui/core/Button';

//Local components
import LoadingDialog from '../components/LoadingDialog';
import DialogOkCancel from '../components/DialogOkCancel';

//Local functions
import { validate, hasErrors, validateAll, required, email } from '../components/Validators';
import useApi from '../api';
import { recuperarPassword } from '../api/cuenta';
import Snackbar from '../components/Snackbar';
import BodyBasePanel from '../components/pages/BodyBasePanel';

const styles = theme => ({
    mainPanel: {
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 600,
        },
    },
})

const fieldsValidators = {
    'email': [required, email],
    'reCAPTCHAValue': [required],
}

const initialData = {
    email: '',
    reCAPTCHAValue: '',
}

const defaultErrors = {
    email: '',
}

const RestablecePassword = ({ ...props }) => {

    const { classes } = props;

    const titulo = "Restablece tu contraseña";
    const subtitle = <>
        Si olvidaste tu contraseña, ingresa el email de tu cuenta, verifica que no seas un "robot" y oprime el botón ENVIAR.
        <br />
        <br />
        Recibirás un correo electrónico con las	instrucciones para registrar una nueva contraseña.
        <br />
    </>
    const button = "Enviar";

    const recaptchaRef = useRef(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState(defaultErrors);

    const [notificacion, setNotificacion] = useState(null);

    const [{ data: recuperaPasswordData, loading: loadingRecuperaPassword, error: errorRecuperaPassword }, recuperaPasswordApi] = useApi(null, { defaultError: false });

    useEffect(() => {
        if (dirty === true) {
            const errors = validateAll(data, fieldsValidators);
            setIsValid(!hasErrors(errors));
        }
    }, [data, dirty])

    useEffect(() => {
        if (!isUndefined(recuperaPasswordData)) {
            setShowDialog(true);
        }
    }, [recuperaPasswordData])

    useEffect(() => {
        if (errorRecuperaPassword) {
            let msg = "Servicio no disponible, intente nuevamente";
            if (errorRecuperaPassword.response && errorRecuperaPassword.response.data && errorRecuperaPassword.response.data.error) {
                msg = errorRecuperaPassword.response.data.error;
            }
            notificarError({ mensaje: msg, autoHide: false });
        }
    }, [errorRecuperaPassword]);

    const handleChangeReCAPTCHA = value => {
        setData({
            ...data,
            reCAPTCHAValue: value
        });
        setDirty(true);
    }

    const handleExpiredReCAPTCHA = () => {
        setData({
            ...data,
            reCAPTCHAValue: ''
        });
        setDirty(true);
    }

    const handleErroredReCAPTCHA = () => {
        setData({
            ...data,
            reCAPTCHAValue: ''
        });
        setDirty(true);
    }

    const handleChangeValue = name => event => {
        const value = event.target.value;
        const error = validate(value, fieldsValidators[name]);
        setData({
            ...data,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: error
        })
        setDirty(true);
    }

    const handleOnBlur = name => event => {
        const value = event.target.value;
        const error = validate(value, fieldsValidators[name]);
        setErrors({
            ...errors,
            [name]: error
        })
    }

    const handleAceptarDialog = () => {
        setShowDialog(false);
        setData(initialData);
    }

    const handleOk = () => {
        const errors = validateAll(data, fieldsValidators);

        if (!hasErrors(errors)) {
            recaptchaRef.current.reset();
            setIsValid(false);
            recuperaPasswordApi(recuperarPassword(data.email.trim(), data.reCAPTCHAValue))
        } else {
            setErrors(errors);
        }
    }

    const notificarError = (notificacion) => {
        buildNotificacion(notificacion, 'error')
    }

    const buildNotificacion = (_notificacion, tipo) => {
        if (typeof _notificacion === 'string') {
            _notificacion = {
                id: 0,
                mensaje: _notificacion,
            }
        }
        if (tipo) {
            _notificacion = {
                ..._notificacion,
                tipo,
            }
        }
        setNotificacion(notificacion || _notificacion);
    }

    const handleSnackbarDismiss = (id) => {
        setNotificacion(null);
    }

    return (
        <>
            <BodyBasePanel title={titulo} subtitle={subtitle} hideSocialMedia>
                <div className={classes.mainPanel}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: 12, justifyContent: 'center' }}>
                            <TextField
                                fullWidth
                                margin="normal"
                                field="email"
                                label="Email"
                                value={data.email}
                                onChange={handleChangeValue('email')}
                                onBlur={handleOnBlur('email')}
                                errors={errors}
                            />
                        </div>
                        <div style={{ marginBottom: 24, display: 'flex', justifyContent: 'center' }}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeHbbkUAAAAAJK2nLGi3iIFuQTrak5iAFHkQ-cU"
                                onChange={handleChangeReCAPTCHA}
                                onExpired={handleExpiredReCAPTCHA}
                                onErrored={handleErroredReCAPTCHA}
                            />
                        </div>
                        <Button
                            style={{ alignSelf: 'center' }}
                            disabled={!isValid}
                            variant="contained"
                            color="primary"
                            onClick={handleOk}>
                            {button}
                        </Button>
                    </div>
                </div>
            </BodyBasePanel>
            <LoadingDialog open={loadingRecuperaPassword} />
            <DialogOkCancel
                open={showDialog}
                msg="Se ha enviado un correo con las instrucciones para restablecer la contraseña."
                onOk={handleAceptarDialog} />
            {notificacion &&
                <Snackbar
                    key={notificacion.id}
                    position={'tc'}
                    notificacion={notificacion}
                    onDismiss={handleSnackbarDismiss} />
            }

        </>
    )
}

RestablecePassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RestablecePassword);