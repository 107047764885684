import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';

export const img = (path) => `${process.env.PUBLIC_URL}${path}`;

export function onlyNumbers(value) {
    return value.replace(/[^0-9]/g, '');
}

export function convertToNumber(value) {
    if (isString(value) && !isEmpty(value)) {
        if (value.includes('.')) { //Lo convertimos a un flotante si tiene un punto
            return parseFloat(value.replace(/[^0-9-.]/g, ''));
        } else {
            return parseInt(onlyNumbers(value), 10);
        }
    }
    return value;
}

const _isBlank = (v) => !isNil(v) && v !== '';

export function hasChanged(a, b, props) {
    const _a = pickBy(props ? pick(a, props) : a, _isBlank);
    const _b = pickBy(props ? pick(b, props) : b, _isBlank);

    return JSON.stringify(_a, Object.keys(_a).sort()) !== JSON.stringify(_b, Object.keys(_b).sort());
}

export function downloadUrl(dataUrl, filename) {
    // Construct the 'a' element
    var link = document.createElement("a");
    link.download = filename;
    link.target = "_blank";

    // Construct the URI
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);
}