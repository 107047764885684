import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// import PropTypes from 'prop-types';
import 'moment/locale/es';

import { withStyles } from '@material-ui/core/styles';

import withRoot from './withRoot';
import ProcesaToken from './pages/ProcesaToken';
import RestablecePassword from './pages/RestablecePassword';
import PagoOpenpay3dSecureContinue from './pages/PagoOpenpay3dSecureContinue';
import Home from './pages/Home';

const styles = theme => ({
    root: {
        // backgroundImage: `url(${img("/images/fondo-main1.jpg")})`,
        backgroundColor: '#659adc',
        backgroundSize: 'cover',
        height: '100vh',
        minHeight: '100vh',
    },
});

const appContextInitialState = {
    cuenta: {},
    app: {},
    filtros: {},
    colegio: {},
    notificacion: {},
    notificaciones: [],
    notificar: () => { },
    notificarInfo: () => { },
    notificarError: () => { },
    notificarWarn: () => { },
    actualizaApp: () => { },
    actualizaFiltros: () => { },
    actualizaCuenta: () => { },
    tieneCuentaPermisos: () => { },
    wipeAllData: () => { },
}

export const AppContext = React.createContext(appContextInitialState);

//TODO Agregar estas rutas para el app
// https://tutores.escolastia.com/content/activacuenta -> Instrucciones para activar cuenta
// https://tutores.escolastia.com/content/nuevocolegio -> Como avisar a un colegio para contratar el servicio

const App = (props) => {
    //const { width } = props.size;
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path='/validacuenta' render={(others) => <ProcesaToken {...others} origin="cuenta" />} />
                <Route exact path='/cambiaemail' render={(others) => <ProcesaToken {...others} origin="email" />} />
                <Route exact path='/cambiapassword' render={(others) => <ProcesaToken {...others} origin="password" />} />
                <Route exact path='/restablecepassword' render={() => <RestablecePassword />} />
                <Route exact path='/pagos/openpay/3dsecure/continue' render={() => <PagoOpenpay3dSecureContinue />} />
                <Route path='/' render={() => <Home {...props} />} />
            </Switch>
        </BrowserRouter>
    )
};

App.propTypes = {
    //classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(App));