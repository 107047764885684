import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'

// Material UI Styles
import withRoot from '../withRoot';
import { withStyles } from '@material-ui/core/styles';

// Material UI Components
import Typography from '@material-ui/core/Typography';
import TextField from '../components/TextField';
import Button from '@material-ui/core/Button';

//Local components
import LoadingDialog from '../components/LoadingDialog';

//Local functions
import { validate, hasErrors, validateAll, required } from '../components/Validators';

// Back-end
import CheckCircleOutlineIcon from '../icons/CheckCircleOutline';
import useApi from '../api';
import { validaToken } from '../api/cuenta';
import BodyBasePanel from '../components/pages/BodyBasePanel';

const styles = theme => ({

})

const fieldsValidators = {
    'password': [required],
    'confirma': [required],
}

const initialData = {
    password: '',
    confirma: '',
}

const defaultErrors = {
    password: '',
    confirma: '',
}

const ProcesaToken = ({ ...props }) => {
    const { origin } = props;
    const [home, setHome] = useState('');
    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState(defaultErrors);
    const [token, setToken] = useState(null);

    const [{ data: vaidaTokenData, loading: loadingValidaToken, error: errorValidaToken }, validaTokenApi] = useApi();

    let button = "";
    if (origin === "cuenta") {
        button = "Activar cuenta";
    } else if (origin === 'password') {
        button = "Restablecer";
    }

    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const _token = params.get('token');

        if (_token) {
            setToken(_token);
            if (origin === 'email') {
                validaTokenApi(validaToken(_token, origin))
            }
        }
    }, [origin]);

    useEffect(() => {
        if (!isUndefined(vaidaTokenData)) {
            setHome(vaidaTokenData);
        }
    }, [vaidaTokenData])

    const handleChangeValue = name => event => {
        const value = event.target.value;
        const error = validate(value, fieldsValidators[name]);
        setData({
            ...data,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: error
        })
        // setDirty(true);
    }

    const handleOnBlur = name => event => {
        const value = event.target.value;
        const error = validate(value, fieldsValidators[name]);
        setErrors({
            ...errors,
            [name]: error
        })
    }

    const handleOk = () => {
        let _errors = validateAll(data, fieldsValidators);

        if (origin === 'cuenta' || origin === 'password') { //Validamos que el password y confirma sean iguales
            if (data.password !== data.confirma && isEmpty(_errors.confirma)) {
                _errors.confirma = 'Las contraseñas no coinciden';
            }
        }

        if (!hasErrors(_errors)) {
            // setErrorServer(null);
            validaTokenApi(validaToken(token, origin, data.password))
        } else {
            setErrors(_errors);
        }
    }

    const calculateTitle = () => {
        if (!errorValidaToken && home === '') {
            if (origin === "cuenta") {
                return "Activar tu cuenta";
            } else if (origin === 'password') {
                return "Restablecer contraseña";
            } else if (origin === 'email') {
                return "Cambiar email";
            }
        }
        return null;
    }

    const calculateSubTitle = () => {
        if (!errorValidaToken && home === '') {
            if (origin === "cuenta") {
                return <>Para activar la cuenta deberás establecer la contraseña para poder acceder. <br /><br />Captura tu contraseña y su confirmación para continuar.</>;
            } else if (origin === 'password') {
                return "Captura tu nueva contraseña y su confirmación para restablecer tu acceso a tu cuenta.";
            }
        }
        return null;
    }

    return (
        <>
            <BodyBasePanel title={calculateTitle()} subtitle={calculateSubTitle()} hideSocialMedia errorServer={errorValidaToken}>
                {(origin === 'cuenta' || origin === 'password') && home === '' &&
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ padding: 24, marginBottom: 24, }}>
                            <TextField
                                fullWidth
                                autoComplete="new-password"
                                margin="normal"
                                field="password"
                                type="password"
                                label="Contraseña"
                                value={data.password}
                                onChange={handleChangeValue('password')}
                                onBlur={handleOnBlur('password')}
                                errors={errors}
                            />
                            <TextField
                                fullWidth
                                autoComplete="new-password"
                                margin="normal"
                                field="confirma"
                                type="password"
                                label="Confirma la contraseña"
                                value={data.confirma}
                                onChange={handleChangeValue('confirma')}
                                onBlur={handleOnBlur('confirma')}
                                errors={errors}
                            />
                        </div>
                        <Button variant="contained" color="primary" onClick={handleOk}>
                            {button}
                        </Button>
                    </div>
                }
                <Typography variant="h6" align="center" style={{ margin: '24px 0px' }}>
                    {origin === 'cuenta' &&
                        "La cuenta ha sido activada correctamente."
                    }
                    {origin === 'password' &&
                        "La constraseña fue restablecida correctamente."
                    }
                    {origin === 'email' &&
                        "La solicitud para cambio de email se procesó correctamente."
                    }
                </Typography>
                {home !== '' &&
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <CheckCircleOutlineIcon style={{ fontSize: 65, alignSelf: 'center', color: '#7ab800' }} />
                        {(origin === 'cuenta' || origin === 'password') &&
                            <Button variant="contained" color="primary" style={{ alignSelf: 'center', marginTop: 24 }} href={home}>
                                Inicia sesión
                            </Button>
                        }
                    </div>
                }
            </BodyBasePanel>
            <LoadingDialog open={loadingValidaToken} />

        </>
    )

}

ProcesaToken.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    origin: PropTypes.string.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(ProcesaToken));