import validator from 'validator';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import isDate from 'lodash/isDate';
import trim from 'lodash/trim';
import moment from 'moment';

//Validators....
export function required(value) {
    if (isString(value) && (isNil(value) || isEmpty(value.trim()))) { //Para strings
        return 'Campo obligatorio';
    } else if (isNumber(value) && isNil(value)) { //Para números
        return 'Campo obligatorio';
    } else if (isNil(value)) { //Para cualquier otro ??
        return 'Campo obligatorio';
    }
}

export function email(value) {
    if (!isEmpty(value.trim()) && !validator.isEmail(value.trim())) {
        return 'No es un email válido';
    }
}

export function phone(value) {
    value = isNumber(value) ? value + "" : value;
    if (!isEmpty(value.trim()) && length({ max: 10, min: 10 })(value)) {
        return 'Número incorrecto, deben ser 10 dígitos';
    }
}

export function creditCard(value) {
    value = isNumber(value) ? value + "" : value;
    if (!validator.isCreditCard(value)) {
        return 'No es una tarjeta de crédito válida';
    }
}

export function creditCardExpired(value) {
    try {
        const mesExpiracion = value.substring(0, 2);
        const anoExpiracion = value.substring(2, 4);
        const dateStr = `01/${mesExpiracion}/${anoExpiracion}`;

        if (!validator.isLength(dateStr.trim(), { min: 8, max: 8 })) {
            return 'Fecha incorrecta';
        }

        const fechaExpiracion = moment(dateStr, "DD/MM/YY", true);

        if (!fechaExpiracion.isValid()) {
            return 'Fecha incorrecta';
        }
        if (fechaExpiracion.isBefore()) {
            return 'La tarjeta ya expiró';
        }
    } catch (e) {
        return 'Fecha incorrecta';
    }
}

export const regex = (spec) => {
    let re = spec;
    return (value) => {
        if (value && isString(value)) {
            return value.match(re) ? '' : 'Dato incorrecto'
        } else {
            return (value + '').match(re) ? '' : 'Dato incorrecto'
        }
    }
}

export const CURP = (value) => {
    if (value && isString(value)) {
        const validado = checkRegexCURP(value);

        if (!validado)  //Coincide con el formato general?
            return 'CURP Incorrecta';
    } else {
        return 'CURP Incorrecta';
    }
}

export const CURPDV = (value) => {
    if (value && isString(value)) {
        const validado = checkRegexCURP(value);

        if (!validado)  //Coincide con el formato general?
            return 'CURP Incorrecta';

        const curp17 = validado[1];

        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;
        for (var i = 0; i < 17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito === 10) lngDigito = 0;
        return parseInt(validado[2], 10) !== lngDigito ? 'CURP Incorrecta' : '';
    } else {
        return 'CURP Incorrecta';
    }
}

export const validMomentDate = (spec) => {
    let format = 'YYYY-MM-DD'
    if (isObject(spec)) {
        ({ format } = spec);
    } else {
        format = spec || format;
    }
    return (value) => {
        if (value && isString(value)) {
            return moment(value, format).isValid() ? '' : 'Fecha incorrecta';
        } else if (value && isDate(value)) {
            return moment(value).isValid() ? '' : 'Fecha incorrecta';
        } else if (value && value._isAMomentObject) {
            return value.isValid() ? '' : 'Fecha incorrecta';
        } else {
            return 'Fecha incorrecta';
        }
    }
}

export const validDate = (spec) => {
    let format = 'YYYY-MM-DD'
    if (isObject(spec)) {
        ({ format } = spec);
    } else {
        format = spec || format;
    }
    return (value) => {
        if (value === 'Invalid date') {
            return 'Fecha incorrecta';
        }
        const date = moment(value).format(format)
        return date === 'Invalid date' ? 'Fecha incorrecta' : ''
    }
}

/* spec debe ser un objeto con { min, max } o bien un número con la longitud exacta */
export const length = (spec) => {
    let min, max, label = 'caracteres';
    if (isObject(spec)) {
        ({ min, max, label = label } = spec);
    } else {
        min = spec;
        max = spec;
    }
    return (value) => {
        // Trim regresa '' si es null u undefined
        const v = trim(value);
        if (min === max) {
            if (v.length !== min) {
                return `Deben ser ${min} ${label}`;
            }
        } else {
            if (min && v.length < min) {
                return `Mínimo ${min} ${label}`;
            }
            if (max && v.length > max) {
                return `Máximo ${max} ${label}`;
            }
        }
    }
}

export function isNotNone(value, msg) {
    if (isString(value) && (isNil(value) || isEmpty(value.trim()) || value === "none")) { //Para strings
        return msg || 'Campo obligatorio';
    }
}

export function isNotZero(value, msg) {
    value = convertToNumber(value);
    if (isNumber(value) && isNil(value) && value > 0) { //Para números
        return msg || 'Campo obligatorio';
    }
}

export function gtThanZero(value, msg) {
    value = convertToNumber(value);
    if (!isNumber(value)) {
        return 'No es un valor numérico';
    }
    if (isNil(value) || value <= 0) { //Para números
        return msg || 'Campo obligatorio';
    }
}

// export function length(value, min, max, leyend) {
//     const label = leyend || "caracteres";
//     if (isString(value) && !validator.isLength(value.trim(), { min: min || 0, max: max })) {
//         return `Deben ser al menos ${max} ${label}`;
//     }
// }

export function montoRecarga(value, min, max, msg) {
    let monto = parseFloat(value.replace(/[^0-9-.]/g, ''));
    if (monto < min || monto > max) {
        return msg || `El dato es incorrecto`;
    }
}


//Support functions --- TODO mover al archivo utils para que las puedan usar todos??? de hecho 2 de ellas están repetidas
function checkRegexCURP(value) {
    const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    return value.match(re);
}

function onlyNumbers(value) {
    return value.replace(/[^0-9]/g, '');
}

function convertToNumber(value) {
    if (isString(value) && !isEmpty(value)) {
        if (value.includes('.')) { //Lo convertimos a un flotante si tiene un punto
            return parseFloat(value.replace(/[^0-9-.]/g, ''));
        } else {
            return parseInt(onlyNumbers(value), 10);
        }
    }
    return value;
}

//General functions....

export function validate(value, validators) {
    var error = undefined;
    if (isArray(validators)) {
        return runValidators(value, validators)
    } else if (isObject(validators)) { //TODO Cuando se quieren validar contextos
        if (!isEmpty(validators.context)) {

        }
    }
    return error
}

export function validateAll(data, validators) {
    let errors = {}
    if (!isEmpty(data)) {
        Object.entries(data).forEach(entry => {
            const [key, value] = entry;
            const fieldValidators = validators[key]
            errors[key] = '';
            if (!isEmpty(fieldValidators)) {
                const error = validate(value, fieldValidators)
                errors[key] = error;
            }
        });
    }
    return errors;
}

export function hasErrors(errors) {
    const i = Object.values(errors).findIndex(e => !isEmpty(e));
    return i >= 0;
}

const runValidators = function (value, validators) {
    for (let i = 0; i < validators.length; i++) {
        const validator = validators[i];
        let error = undefined;
        if (isFunction(validator)) {
            error = validator(value);
        } else if (validator.fn) {
            let args = validator.extras.slice(0) || [];
            args.splice(0, 0, value);
            error = validator.fn.apply(this, args);
        }
        if (!isEmpty(error)) {
            return error
        }
    }
    return '';
}